.formation-card {
  max-width: 400px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #e7e7e7;
}
.formation-card:hover {
  box-shadow: 0 0 7px 1px #b3b3b3;
  border: none;
}
.formation-card-media {
  height: 200px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  /* animation: backgroundSizeAnimation 1s ease alternate; */
}

.formation-card:hover .formation-card-media {
  background-size: 140%;
  background-position: center;
  cursor: pointer;
}

.formation-card-content {
  padding: 20px;
}

.formation-card-icon {
  width: 30px;
  height: auto;
}

.formation-card-button {
  font-size: 14px;
  max-width: 250px;
  min-height: 50px;
}
@keyframes backgroundSizeAnimation {
  0% {
    background-size: cover;
  }
  100% {
    background-size: 140%;
  }
}
