.about_area .about_info {
  padding-left: 68px;
}

@media (max-width: 767px) {
  /* line 7, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 7, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info {
    padding-left: 30px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 7, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info {
    padding-left: 30px;
    padding-top: 0;
    padding-bottom: 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 7, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info {
    padding-left: 30px;
    padding-top: 0;
  }
}

/* line 31, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
.about_area .about_info h3 {
  font-size: 36px;
  line-height: 55px;
  font-weight: 400;
  color: #2c2c2c;
}

@media (max-width: 767px) {
  /* line 31, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 31, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

/* line 45, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
.about_area .about_info p {
  font-size: 16px;
  line-height: 28px;
  color: #727272;
  margin-bottom: 22px;
  margin-top: 28px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 51, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info p br {
    display: none;
  }
}

/* line 57, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
.about_area .about_info ul {
  margin-bottom: 56px;
}

@media (max-width: 767px) {
  /* line 57, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
  .about_area .about_info ul {
    margin-bottom: 30px;
  }
}

/* line 62, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
.about_area .about_info ul li {
  font-size: 16px;
  line-height: 28px;
  color: #727272;
  position: relative;
  padding-left: 28px;
  margin-bottom: 10px;
  font-weight: 400;
  z-index: 1;
  text-align: left;
  font-weight: 400;
}

/* line 71, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
.about_area .about_info ul li::before {
  position: absolute;
  left: 0;
  background-image: url(assets/images/about/check.svg);
  width: 16px;
  height: 16px;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* line 83, ../../Arafath/CL/CL October/213. Business/HTML/scss/_about.scss */
.about_area .about_info .boxed-btn3 {
  padding: 13px 46px 14px 46px;
}

b {
  color: #ff7300;
}
