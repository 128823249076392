.slider_text_fade_in {
  display: none;
  width: 100%;
}

.hero-slider-slide-background {
  position: relative;
}
.hero-slider-slide-background::before {
  content: "";
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c2c2c;
  opacity: 0.6; /* Black background with 50% opacity */
}
