.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* line 6, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top {
  padding-top: 50px;
  padding-bottom: 30px;
  background: #2c2c2c;
}

@media (max-width: 767px) {
  /* line 6, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .footer_top {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  /* line 14, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 14, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

/* line 21, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_title {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
  text-align: left;
}

@media (max-width: 767px) {
  /* line 21, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}

/* line 31, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_logo {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 31, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_logo {
    margin-bottom: 20px;
  }
}

/* line 41, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p {
  color: #c7c7c7;
}

/* line 44, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text {
  font-size: 16px;
  color: #b2b2b2;
  margin-bottom: 23px;
  font-weight: 400;
  line-height: 28px;
}

/* line 50, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain {
  color: #b2b2b2;
  font-weight: 400;
}

/* line 53, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain:hover {
  color: #ff7300;
  border-bottom: 1px solid #ff7300;
}

/* line 59, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a {
  font-weight: 500;
  color: #b2b2b2;
}

/* line 63, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
  color: #ff7300;
  border-bottom: 1px solid #ff7300;
}

/* line 67, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a.first {
  margin-bottom: 10px;
}

/* line 75, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li {
  color: #c7c7c7;
  font-size: 13px;
  line-height: 42px;
}

/* line 79, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a {
  color: #c7c7c7;
}

/* line 81, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a:hover {
  color: #ff7300;
}

/* line 87, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 90, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding-left: 20px;
  font-size: 16px;
  color: #000;
  border: none;
}

/* line 98, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input::placeholder {
  font-size: 16px;
  color: #919191;
}

/* line 103, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #ff7300;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
}

.footer .footer_top .footer_widget ul {
  padding: 0;
}
.footer .footer_top .footer_widget {
  padding-left: 15px;
  padding-right: 15px;
}
/* line 117, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_text {
  font-size: 16px;
  color: #bababa;
}

/* line 123, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .copy-right_text {
  padding-bottom: 30px;
  background: #1f1f1f;
}

/* line 126, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .copy-right_text .footer_border {
  padding-bottom: 30px;
}

/* line 129, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right {
  font-size: 13px;
  color: #c7c7c7;
  margin-bottom: 0;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 129, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .copy-right_text .copy_right {
    font-size: 13px;
  }
}

/* line 137, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right a {
  color: #ff7300;
}

/* line 142, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {
  /* line 142, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
  .footer .socail_links {
    margin-top: 30px;
  }
}

/* line 148, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .socail_links ul li {
  display: inline-block;
}

/* line 151, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .socail_links ul li a {
  font-size: 18px;
  color: #c3b2f0;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  background: #565656;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 40px !important;
  margin-right: 7px;
  color: #ffffff;
  line-height: 41px !important;
}

/* line 164, ../../Arafath/CL/CL October/213. Business/HTML/scss/_footer.scss */
.footer .socail_links ul li a:hover {
  color: #fff;
  background: #ff7300;
}
