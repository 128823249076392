.Container {
  margin: 24px auto 0;
  padding: 0;
  width: 80%;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  font-weight: 300 !important;
}

@media screen and (max-width: 500px) {
  .Container {
    font-size: 1rem !important;
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  .Container {
    font-size: 1.3rem !important;
  }
}
