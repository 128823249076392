.root_documents_page {
  margin-top: 30px;
  margin-bottom: 80px;
}

.root_documents_page .accordion {
  margin-bottom: 10px;
  box-shadow: none;
}

.root_documents_page .accordion-expanded {
  margin: 0;
}

.root_documents_page .accordionSummary {
  /* background-color: rgb(216 240 226); */
  color: black;
  font-weight: 400;
}

.root_documents_page .accordionSummary:hover {
  /* background-color: #247f4a !important; */
}

.root_documents_page .accordionDetails {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 10px;
}

.root_documents_page .closeButton {
  margin-left: auto;
  color: #555;
}

.root_documents_page .title {
  font-size: 1.9rem;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .root_documents_page .title {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  .root_documents_page .title {
    font-size: 1.7rem !important;
  }
}
